import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SearchFieldModule } from "src/app/shared/components/search-field/search-field.module";
import { ToasterNotificationModule } from "src/app/shared/components/toaster-notification/toaster-notification.module";
import { SharedModule } from "../shared/shared.module";
import { TripsModule } from "../trips/trips.module";
import { PlanningBusesModule } from "./buses/buses.module";
import { SearchModule } from "./components/search/search.module";
import { PlanningDriversModule } from "./drivers/drivers.module";
import { PlanningRoutingModule } from "./planning-routing.module";
import { PlanningComponent } from "./planning.component";
import { OvernightCheckboxComponent } from "./components/overnight-checkbox/overnight-checkbox.component";
import { TimeRoundService } from "./common/services/time-round.service";
import { ScaleConfigurationBaseComponent } from "./components/scale-base-configuration/scale-configuration-base.component";

@NgModule({
    declarations: [
        OvernightCheckboxComponent,
        PlanningComponent,
        ScaleConfigurationBaseComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        PlanningRoutingModule,
        SharedModule,
        TripsModule,
    ],
    exports: [
        NgbModule,
        PlanningBusesModule,
        ReactiveFormsModule,
        SearchFieldModule,
        SearchModule,
        ToasterNotificationModule,
        TripsModule,
    ],
    providers: [TimeRoundService],
})
export class PlanningModule {}

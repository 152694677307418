import { Component, OnDestroy } from "@angular/core";
import { TabConfiguration } from "src/app/shared/components/tabs-container/tab-configuration";
import { ToasterNotificationService } from "../shared/components/toaster-notification/toaster-notification.service";
import { PlanningSynchronizationService } from "./planning-synchronization.service";
import { Subject } from "rxjs";
import { takeUntil, debounceTime, skip, filter } from "rxjs/operators";
import { IScaleConfiguration } from "./scale-changed-event";
import {
    SelectedZoomSync,
    SelectedZoomStorageModel,
    FromScaleChangedEvent,
} from "../services/ui-state/selected-item-sync/selected-zoom-sync";
import { UserSettingsService } from "../services/user-settings.service";
import {
    SelectedPlanningDateSync,
    SelectedPlanningDateStorageModel,
} from "../services/ui-state/selected-item-sync/selected-planning-date-sync";
import * as _ from "lodash";
import { NavigationEnd, Router } from "@angular/router";
import { SelectedPlanningTabSync } from "../services/ui-state/selected-item-sync/selected-planning-tab-sync";
import { MainRouting } from "../main-routing.enum";
import { PlanningTabs } from "./models/planning-tabs.model";
import { AutoUnsubscribe } from "../shared/decorators/auto-unsubscribe";

const MINIMUM_SCALE_CONFIGURATION_INDEX = 1;

@Component({
    selector: "ge-planning",
    templateUrl: "./planning.component.html",
    styleUrl: "./planning.component.scss",
})
@AutoUnsubscribe()
export class PlanningComponent implements OnDestroy {
    tabsConfiguration: TabConfiguration[] = [
        {
            linkUrl: "buses",
            i18nTitle: "busListPage.busListHeader.bussesList",
        },
        {
            linkUrl: "drivers",
            i18nTitle: "Drivers.driversHeader.title",
        },
    ];
    private unsubscribe$ = new Subject<void>();
    private selectedZoomSync = new SelectedZoomSync().withCallBack(
        this.onZoomConfigLoaded.bind(this),
    ) as SelectedZoomSync;
    private selectedPlanningDateSync =
        new SelectedPlanningDateSync().withCallBack(
            this.onPlanningDateConfigLoaded.bind(this),
        ) as SelectedPlanningDateSync;
    private selectedTabSync = new SelectedPlanningTabSync();

    constructor(
        private toasterNotificationService: ToasterNotificationService,
        private planningSyncService: PlanningSynchronizationService,
        private router: Router,
        userSettingsService: UserSettingsService,
    ) {
        // trigger config loading, `withCallBack` is called as result.
        this.selectedZoomSync.setUserSettingsProvider(userSettingsService);
        this.selectedPlanningDateSync.setUserSettingsProvider(
            userSettingsService,
        );
        this.selectedZoomSync.load();
        this.selectedPlanningDateSync.load();

        planningSyncService.scaleConfigurationChanged$
            .pipe(debounceTime(800))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((scaleConfiguration: IScaleConfiguration) => {
                this.selectedZoomSync.onSelectedZoomChanged(
                    FromScaleChangedEvent(scaleConfiguration),
                );
                this.selectedPlanningDateSync.onSelectedPlanningDateChanged({
                    date: scaleConfiguration.date,
                });
            });

        if (!this.selectedTabSync.selectedItem) {
            this.selectedTabSync.onSelectedTabChanged({
                selectedTab: PlanningTabs.Buses,
            });
        }

        this.router.navigate([
            `/${MainRouting.Planning}/${this.selectedTabSync.selectedItem.selectedTab}`,
        ]);

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                skip(1),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(({ url }: NavigationEnd) => {
                let selectedTab = "";

                if (url.includes(PlanningTabs.Buses)) {
                    selectedTab = PlanningTabs.Buses;
                } else if (url.includes(PlanningTabs.Drivers)) {
                    selectedTab = PlanningTabs.Drivers;
                }

                if (selectedTab) {
                    this.selectedTabSync.onSelectedTabChanged({ selectedTab });
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onHideToaster(): void {
        this.toasterNotificationService.onHideToaster("slide-in", "slide-out");
    }

    onZoomConfigLoaded(selectedZoom: SelectedZoomStorageModel): void {
        if (!_.isEmpty(selectedZoom)) {
            this.planningSyncService.setScale(
                selectedZoom.index || MINIMUM_SCALE_CONFIGURATION_INDEX,
            );
        }
    }

    onPlanningDateConfigLoaded(
        selectedDate: SelectedPlanningDateStorageModel,
    ): void {
        if (!_.isEmpty(selectedDate)) {
            this.planningSyncService.setDate(selectedDate.date);
        }
    }
}

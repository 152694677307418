import { LocalStorage } from "src/app/services/ui-state/local-storage-decorator";
import { IStorageProvider } from "../storage-provider";
import { UserSetting } from "../user-setting.type";
import { Observable } from "rxjs";
import { BaseSelectedItemSync } from "./base-selected-item-sync";

export const SELECTED_PLANNING_DATE = "SELECTED_PLANNING_DATE";
export class SelectedPlanningDateSync
    extends BaseSelectedItemSync
    implements IStorageProvider
{
    @LocalStorage(true, SELECTED_PLANNING_DATE)
    selectedItem: SelectedPlanningDateStorageModel;

    constructor() {
        super({ isEmitChanges: true });
        super.setStorageProvider(this);
    }

    getInitialData(): Observable<UserSetting> {
        return this.useSettingsProvider();
    }

    onSelectedPlanningDateChanged(item: SelectedPlanningDateStorageModel) {
        if (!super.isValidChange(item)) {
            return;
        }
        this.selectedItem = item;
        super.onTokenChanged(this.selectedItem);
    }

    protected GetSourceName(): string {
        return SELECTED_PLANNING_DATE;
    }
}

export interface SelectedPlanningDateStorageModel {
    date: Date;
}

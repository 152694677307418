import { LocalStorage } from "src/app/services/ui-state/local-storage-decorator";
import { IStorageProvider } from "../storage-provider";
import { UserSetting } from "../user-setting.type";
import { Observable } from "rxjs";
import { BaseSelectedItemSync } from "./base-selected-item-sync";
import { IScaleConfiguration } from "src/app/planning/scale-changed-event";

export const SELECTED_ZOOM_FOR_PLANNING_MODULE =
    "SELECTED_ZOOM_FOR_PLANNING_MODULE";
export class SelectedZoomSync
    extends BaseSelectedItemSync
    implements IStorageProvider
{
    @LocalStorage(true, SELECTED_ZOOM_FOR_PLANNING_MODULE)
    selectedItem: SelectedZoomStorageModel;

    constructor() {
        super({ isEmitChanges: true });
        super.setStorageProvider(this);
    }

    getInitialData(): Observable<UserSetting> {
        // return new BehaviorSubject(this.selectedItem);
        return this.useSettingsProvider();
    }

    onSelectedZoomChanged(item: SelectedZoomStorageModel) {
        if (!super.isValidChange(item)) {
            return;
        }
        this.selectedItem = item;
        super.onTokenChanged(this.selectedItem);
    }

    protected GetSourceName(): string {
        return SELECTED_ZOOM_FOR_PLANNING_MODULE;
    }
}

export interface SelectedZoomStorageModel {
    index: number;
    // could be useful to store next fields
    // dateRange/domain: DateRange
    // zoomConfigAlias: string e.g. ONE_DAY
}

export function FromScaleChangedEvent({
    scaleConfigurationIndex: index,
}: IScaleConfiguration): SelectedZoomStorageModel {
    return { index };
}

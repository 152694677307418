/* src/app/planning/planning.component.scss */
.page-container {
  height: 100vh;
}
.page-container .draggable-trip-item {
  color: rgb(255, 255, 255);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  border-radius: 4px;
  opacity: 0.7;
  padding: 2px 7px;
  pointer-events: none;
}
/*# sourceMappingURL=planning.component.css.map */

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlanningComponent } from "./planning.component";

const routes: Routes = [
    {
        path: "",
        component: PlanningComponent,
        children: [
            { path: "", redirectTo: "buses", pathMatch: "full" },
            {
                path: "buses",
                loadChildren: () =>
                    import("../planning/buses/buses.module").then(
                        (m) => m.PlanningBusesModule,
                    ),
            },
            {
                path: "drivers",
                loadChildren: () =>
                    import("../planning/drivers/drivers.module").then(
                        (m) => m.PlanningDriversModule,
                    ),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PlanningRoutingModule {}
